<template>
  <div>
    <vl-region mod-no-space-top>
      <vl-layout>
        <vl-grid mod-stacked>
          <vl-column>
            <h1>{{ $t('payments.bulk.label') }}</h1>
          </vl-column>
          <vl-column v-if="error">
            <vl-alert class="app-text" mod-error icon="info-circle">
              {{ error }}
            </vl-alert>
          </vl-column>
          <vl-column>
            <a
              href="../files/bulkTemplate.csv"
              download="template.csv"
              class="vl-button button-margin"
            >
              {{ $t('payments.bulk.templateCSV') }}
            </a>
            <div class="vl-form-col--2-12">
              <label for="customFile">{{ $t('payments.bulk.form.file') }}</label>
            </div>
            <div class="vl-form-col--10-12">
              <input
                type="file"
                class="fileButton"
                id="customFile"
                accept=".csv"
                @change="selectFile"
              />
            </div>
            <!--
            <div v-for="(paymentLink, index) in paymentLinks" :key="paymentLink">
              <a :href="paymentLink">{{ $t('payments.bulk.paylink') }} {{ index + 1 }} </a>
            </div>
             -->
            <JsonCSV
              delimiter=";"
              v-if="completeCSVData.length"
              class="vl-button button-margin"
              :data="completeCSVData"
              >{{ $t('payments.bulk.exportCSV') }}</JsonCSV
            >
            <button v-if="pdf" @click="savePDF" class="vl-button button-margin">
              {{ $t('payments.bulk.exportPDF') }}
            </button>
          </vl-column>
        </vl-grid>
      </vl-layout>
    </vl-region>
  </div>
</template>

<script>
import papa from 'papaparse';
import JsonCSV from 'vue-json-csv';
import { jsPDF } from 'jspdf';
import QRCode from 'qrcode';

export default {
  data() {
    return {
      error: '',
      completeCSVData: [],
      pdf: null,
    };
  },
  components: {
    JsonCSV,
  },
  methods: {
    async onComplete(results, file) {
      try {
        this.$store.dispatch('payment/bulk', results.data).then(async response => {
          if (response.status !== undefined) {
            this.error = response.detail;
          } else {
            let containsError = false;
            await Promise.all(
              response.map(async (element, index) => {
                if (!containsError) {
                  if (element.error) {
                    this.error = element.error + ' at element ' + (index + 1);
                    containsError = true;
                  } else if (element.additionalLinks.QR != null) {
                    results.data[index].paylink = element.additionalLinks.QR.paylinkShort;
                    const qrCodeLink = await this.$store.dispatch('paylink/add', {
                      paylink: element.additionalLinks.QR.paylinkShort,
                    });
                    results.data[index].QRLink =
                      window.location.origin + '/paylink?uuid=' + qrCodeLink.uuid;
                  }
                }
              }),
            );
            if (!containsError) {
              const doc = new jsPDF('p', 'pt', 'a4');
              results.data.pop();
              await Promise.all(
                results.data.map(async payment => {
                  QRCode.toDataURL(payment.paylink)
                    .then(url => {
                      doc.text(
                        20,
                        20,
                        'Document Title: ' +
                          payment.documentTitle +
                          '\n' +
                          'Amount: ' +
                          payment.amount +
                          '\n' +
                          'Communication: ' +
                          payment.communicationPart1 +
                          '\n' +
                          'Pay Link: ' +
                          payment.paylink,
                      );
                      doc.addImage(url, 'PNG', 0, 100, 100, 100);
                      doc.addPage();
                    })
                    .catch(err => {
                      this.error = err.message;
                    });
                }),
              );
              this.pdf = doc;
              this.completeCSVData = results.data;
            }
          }
        });
      } catch (err) {
        this.error = err.message;
      }
    },
    async selectFile(e) {
      try {
        this.completeCSVData = [];
        this.pdf = null;
        this.error = '';
        const file = e.target.files[0];
        papa.parse(file, {
          header: true,
          complete: this.onComplete, // your vue methods
        });
      } catch (err) {
        this.error = err.message;
      }
    },
    savePDF() {
      this.pdf.save('pdfData.pdf');
    },
  },
};
</script>

<style>
label,
a,
input {
  font-size: 1.5rem;
}
.fileButton {
  position: relative;
  font-family: calibri;
  width: auto;
  padding: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border: 1px dashed #bbb;
  text-align: center;
  background-color: #ddd;
  cursor: pointer;
}
.button-margin {
  cursor: pointer;
  margin-top: 5px;
  margin-right: 5px;
}
.app-text {
  font-size: 1.5rem;
}
</style>
